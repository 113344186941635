@import "./scss/_bootstrap_custom";
@import "./scss/profile";
@import "./scss/writing";
@import "./scss/common";
@import "./scss/achievements";
@import "./scss/settings";

#loader {
  position: static;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 100%;
  text-align: center;

  img {
    top: 50%;
    transform: translateY(-50%);
    margin-left: auto;
    margin-right: auto;
    position: relative;
    left: 0;
    max-width: 50%;
  }
}
