.circular-chart {
  display: inline-block;

  @media (max-width: 768px) {
    max-width: 95px;
  }

  .circle {
    fill: none;
    stroke-width: 4;
    stroke-linecap: round;
    //animation: progress 1s ease-out forwards;
    //-webkit-animation: progress 1s ease-out forwards;
    //transition: 1s;
  }

  .circleFill {
    opacity: 0.35;
    fill: none;
    stroke-width: 4;
    stroke-linecap: round;
  }

  .tm {
    font-size: 9px;
  }

  .ts {
    font-size: 6px;
  }

  @keyframes progress {
    0% {
      stroke-dasharray: 0 100;
    }
  }
}
