$navbar-padding-x: 0 !default;

// Required
@import "node_modules/bootstrap/scss/functions";
@import "node_modules/bootstrap/scss/variables";
@import "node_modules/bootstrap/scss/mixins";

// Optional
@import "../node_modules/bootstrap/scss/reboot";
@import "../node_modules/bootstrap/scss/type";
@import "../node_modules/bootstrap/scss/grid";
@import "../node_modules/bootstrap/scss/functions";
@import "../node_modules/bootstrap/scss/nav";
@import "../node_modules/bootstrap/scss/navbar";
@import "../node_modules/bootstrap/scss/media";
@import "../node_modules/bootstrap/scss/utilities";
@import "../node_modules/bootstrap/scss/transitions";

$primary-color: #fb8122;
$gray-color: #555555;
$lightgray-color: #bbbbbb;
$enable-shadows: true !default;

$sans-serif-font: 'Baloo 2', cursive;
$serif-font: 'Quattrocento', serif;

html, body, #root {
  height: 100%;
}

body {
  font-family: $sans-serif-font;
}

.highlight {
  color: $primary-color;
}

a.highlight {
  &:hover {
    color: $primary-color;
  }
}

a {
  outline: none;
  text-decoration: none;
  font-family: $sans-serif-font;
}

.btn {
  color: #FFF;
  background: $primary-color;
  padding: 6px 20px;
  display: inline-block;
  border: none;
  font-size: 1rem;
  font-family: $sans-serif-font;
  @include border-radius(30px);

  &:focus {
    outline: 0;
  }

  &.btn-gray {
    background: $gray-color;

    &:hover {
      background: darken($gray-color, 10%);
    }
  }

  &.btn-lightgray {
    background: $lightgray-color;

    &:hover {
      background: darken($lightgray-color, 10%);
    }
  }

  &.btn-facebook {
    background: #4267B2;
    margin-left: 5px;
    margin-right: 5px;

    &:hover {
      background: darken(#4267B2, 10%);
    }
  }

  &.btn-twitter {
    background: #1DA1F2;
    margin-left: 5px;
    margin-right: 5px;

    &:hover {
      background: darken(#1DA1F2, 10%);
    }
  }

  &:hover {
    color: #FFF;
    text-decoration: none;
    background: darken($primary-color, 10%);
  }
}

footer {
  margin-top: 100px;
  background: #252525;
  font-family: $sans-serif-font;

  p {
    color: #AAA;
    font-size: 1.2rem;
  }

  a {
    color: #DEDEDE;
    text-decoration: none;
    font-size: 1rem;

    &:hover {
      color: darken(#DEDEDE, 20%);
    }
  }
}
