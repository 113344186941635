@import "node_modules/bootstrap/scss/mixins";

#writing-page {
  background: #f7f7f7;
  height: 100%;
  text-align: center;
  padding-top: 20px;
  padding-bottom: 20px;
  position: relative;

  &.nd-mode.split-testing-f1-n2-g1 {
    #writing-area input,
    #no-distractions,
    #done-writing,
    #words-count {
      display: none;
    }

    #exit-fullscreen {
      display: inline-block;
    }
  }

  &.nd-mode.split-testing-f1-n2-g2 {
    #no-distractions,
    {
      display: none;
    }

    #exit-no-distractions {
      display: inline-block;
    }
  }

  > #write {
    display: none;
  }

  #writing-area {
    width: 680px;
    max-width: 95%;
    height: 100%;
    margin-left: auto;
    margin-right: auto;
    position: relative;

    @media (max-width: 1024px) {
      height: 90%;
    }

    #writing-inputs {
      background: #fdfdf5;
      height: 100%;
      width: 100%;
      position: relative;
      z-index: 3;
      padding: 30px 0 50px;
    }

    #write {
      font-size: 50px;
      position: absolute;
      right: 96.5%;
      top: 0;
      display: block;
      height: 100%;
      z-index: 2;

      path {
        fill: #F2F2F2;
      }
    }

    input {
      font-size: 24px;
      width: 100%;
      padding: 0 70px;
      border: none;
      background: none;
      outline: none;

      @media (max-width: 1024px) {
        padding: 0 20px;
      }
    }

    textarea {
      background: none;
      margin-top: 10px;
      padding: 0 70px;
      border: none;
      outline: none;
      width: 100%;
      resize: none;
      font-size: 18px;
      height: 100%;
      color: #333333;

      @media (max-width: 1024px) {
        padding: 0 20px;
      }
    }
  }

  #writenextfadedlogo {
    font-size: 50px;
    position: absolute;
    left: -50px;
    top: 100px;
    display: block;
    height: 50px;
    z-index: 2;
    opacity: 0.2;
    transform: rotate(270deg);

    @media (max-width: 833px) {
      display: none;
    }
  }


  #no-distractions {
    position: absolute;
    top: 30px;
    right: 20px;
    width: 140px;
    font-size: 14px;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  #exit-no-distractions {
    display: none;
    position: absolute;
    top: 30px;
    right: 20px;
    width: 140px;
    font-size: 14px;

    @media (max-width: 1024px) {
      display: none;
    }
  }

  #exit-fullscreen {
    display: none;
    position: absolute;
    top: 30px;
    right: 20px;
    font-size: 40px;
    border: none;
    line-height: 0;
    height: 20px;
    overflow: hidden;
    background: none;
    color: #d6d6d6;
    padding: 0;

    &:focus {
      outline: 0;
    }

    &:hover {
      color: #666666;
    }
  }

  #done-writing {
    position: absolute;
    top: 70px;
    right: 20px;
    width: 140px;
    font-size: 14px;

    @media (max-width: 1024px) {
      top: auto;
      right: auto;
      bottom: 20px;
      left: 5%;
    }
  }


  .wheels {
    position: absolute;
    top: 150px;
    right: 7px;
    margin-right: 10px;

    .circular-chart {
      display: block;
      margin-bottom: 20px;
    }

    @media (max-width: 900px) {
      display: none;
    }

    @media (max-width: 1330px) {
    }
  }

  .alpha .wheels {

  }

  #words-count {
    position: absolute;
    bottom: 15px;
    right: 20px;
    color: #999;
    font-size: 18px;
    font-weight: 500;
  }

  &.split-testing-f1-n2-g2 #words-count {
    top: 530px;
    right: 33px;
    text-align: center;
    width: 120px;
  }

}

#writings-page {
  .writing-content {
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 18px;
  }

  .writing-info {
    font-size: 14px;
    color: #666;
  }

  .writing-actions {
    a {
      margin-right: 20px;
    }
  }

  #load-more {
    text-align: center;

    button {
      background: none;
      border: solid 2px #999;
      color: #999;
      font-size: 20px;
      font-weight: 500;
      @include border-radius(30px);
      padding: 5px 20px;

      &:hover {
        border-color: #fb8122;
        color: #fb8122;
      }

      &:focus {
        outline: none;
      }
    }
  }
}
