// Required
@import "../node_modules/bootstrap/scss/transitions";

@import "./circle";
@import "./modal";

.profile-page {
  #profile-main {
    h2 + p {
      font-size: 1.4rem;
    }
    .user-info {
      height: 120px;
    }
    .set-goals {
      width: 120px;
      text-align: center;
    }
  }

  #profile-writings {
    // Hide stroke for heatmap.
    path[d="M902,19L1082,19L1082,37L902,37Z"] {
      stroke: none;
    }

    rect {
      cursor: pointer;
    }

    .writing {
      .words {
        font-size: 20px;
        font-weight: 500;
        margin-bottom: 5px;
        color: #555555;
      }

      .writing-date {
        span {
          background-color: #fbf2d4;
          display: inline-block;
          text-align: center;
          padding: 15px 15px 30px;
          height: 80px;
          line-height: 1;
          position: relative;
          @include border-radius(4px 4px 0 0);
          font-weight: 500;

          &::after {
            display: block;
            content: "";
            position: absolute;
            bottom: 0;
            left: 0;
            top: 80px;
            padding-bottom: 50%;
            width: 57.7%;
            background-color: #FFF;
            transform-origin: 0 0;
            transform: rotate(-30deg) skewX(30deg);
          }
        }
      }

      .writing-text {
        margin-bottom: 0.2rem;
        word-wrap: break-word;
      }
    }
  }

  .box-info {
    font-weight: normal;
    font-size: 1.1rem;
  }

  .stats-box {
    color: rgba(255, 255, 255, 0.75);
    padding: 10px 10px;
    text-align: center;
    font-size: 1rem;
    @include border-radius(0 10px 10px 0);
    line-height: 1;
    width: 50%;

    &.stats-box-black {
      color: rgba(0, 0, 0, 0.75);
      @include border-radius(10px 0 0 10px);

      .digits {
        color: rgba(0, 0, 0, 1);
      }
    }

    .digits {
      color: rgba(255, 255, 255, 1);
      font-size: 1.2rem;
    }
  }

  .streak-bg {
    background: rgba(0, 175, 181, 1);
  }

  .streak-border {
    background: rgba(0, 175, 181, 0.35);
  }

  .words-bg {
    background: rgba(212, 60, 102, 1);
  }

  .words-border {
    background: rgba(212, 60, 102, 0.35);
  }

  .minutes-bg {
    background: rgba(23, 75, 192, 1);
  }

  .minutes-border {
    background: rgba(23, 75, 192, 0.35);
  }

  .speed-bg {
    background: rgba(251, 129, 34, 1);
  }

  .speed-border {
    background: rgba(251, 129, 34, 0.35);
  }
}

.color-empty {
  background: #eeeeee;
}

.color-scale-1 {
  fill: #FBEECF;
  background: #FBEECF;
}
.color-scale-2 {
  fill: #FBCEA0;
  background: #FBCEA0;
}
.color-scale-3 {
  fill: #FCAE6D;
  background: #FCAE6D;
}
.color-scale-4 {
  fill: #FB852A;
  background: #FB852A;
}

.__react_component_tooltip {
  font-size: 16px !important;
}

#legend {
  span {
    line-height: 1.2;
    vertical-align: middle;
  }

  .color-empty, .color-scale-1, .color-scale-2, .color-scale-3, .color-scale-4 {
    height: 14px;
    width: 14px;
    display: inline-block;
    margin-left: 3px;
  }
}
