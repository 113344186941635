@import "node_modules/bootstrap/scss/mixins";

#settings-page {
  input[type="text"],
  input[type="number"] {
    border: none;
    background-color: #e6e6e6;
    @include border-radius(30px);
    padding: 5px 20px;

    &:focus {
      outline: none;
      background-color: darken(#e6e6e6, 10%);
    }
  }

  input[type="number"] {
    width: 110px;
  }

  input[type="text"] {
    width: 250px;
  }

  input[type="checkbox"] {
    margin-right: 10px;

    + label {
      font-size: 18px;
      margin: 0;
    }
  }

  span.checkbox-info {
    font-size: 14px;
    color: #999;
    margin-left: 25px;
  }
}
