h2.section-title {
  font-size: 1.7rem;
  position: relative;

  &::before {
    background: #fbf2d4;
    content: "";
    position: absolute;
    display: inline-block;
    top: -7px;
    left: -50px;
    width: 150px;
    height: 2.5rem;
    z-index: -1;
  }

  &.gray {
    font-size: 1.5rem;
  }

  &.gray::before {
    background: #f7f7f7;
  }
}
