@import "../node_modules/bootstrap/scss/transitions";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3000;
  height: 100%;
  overflow: scroll;

  .overlay {
    background: rgba(0, 0, 0, 0.5);
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    position: absolute;
    z-index: 3;
  }

  .modal-content {
    background: #FFF;
    width: 600px;
    max-width: 95%;
    height: auto;
    max-height: 90%;
    overflow: auto;
    padding: 30px 40px;
    z-index: 4;
    position: relative;
    margin-left: auto;
    margin-right: auto;
    transform: translateY(-50%);
    top: 50%;

    @media (max-width: 768px) {
      padding: 30px 20px;
    }
  }

  .modal-dismiss {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 40px;
    line-height: 1;
    color: #AAA;
    @include transition(all 0.3s);
    display: inline-block;

    &:hover {
      color: #252525;
    }

    &:focus {
      outline: none;
    }
  }
}

#achv-modal {
  .modal-content {
    padding: 30px 100px;

    @media (max-width: 768px) {
      padding: 30px 20px;
    }
  }

  .achv-title {
    font-size: 60px;
    line-height: 0.85;
    opacity: 0.5;
    color: #999;

    &.achv-pink {
      color: #d43c67;
    }

    &.achv-blue {
      color: #174BC0;
    }

    &.achv-orange {
      color: #FB8121;
    }

    &.achv-green {
      color: #00AFB5;
    }
  }

  .achv-date {
    color: #666666;
    font-weight: light;
  }

  .achv-content {
    font-size: 25px;
  }
}

#done-writing-modal {
  #writing-stats {
    display: inline-block;
    text-align: left;
    line-height: 2;

    strong {
      display: inline-block;
      width: 160px;
      text-align: left;
    }
  }
}
