.achvs {
  > img {
    width: 7.5%;
    margin: 0 2.5% 20px;
    @include transition(transform 0.3s);

    @media (max-width: 768px) {
      width: 15%;
      margin: 0 5% 20px;
    }

    &:hover {
      cursor: pointer;
      transform: scale(1.1);
    }
  }
}

#achievements-text {
  font-size: 18px;
}
